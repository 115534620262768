import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Form, Input, Drawer, DatePicker, Select, InputNumber, Radio } from "antd";

import { useCountriesOptions, useLanguagesOptions } from "../../../../hooks/options";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import { parseYearMonthDayHoursMinutes } from "../../../../config/formats";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function RoomDrawer({
  visible,
  onClose,
  room,
  getRoomsList,
  formApplication,
}) {
  const countriesOptions = useCountriesOptions();
  const languagesOption = useLanguagesOptions();

  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [guestGropsOption, setGuestGroupsOption] = useState([]);
  const [optionReservation, setOptionReservation] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getGuestGroupsOption();
    } else {
      setErrorText(null);
    }
    console.log(room);
  }, [visible]);

  useEffect(() => {
    getBookingRoomOptions(room?.id);
  }, [searchValue, visible]);

  const getBookingRoomOptions = (id) => {
    try {
      const successCalBack = (data) => {
        setRoomData(data);
        const options = data?.rows?.map((item) => {
          return {
            label: item?.room?.name,
            value: item?.id,
          };
        });
        setOptionReservation(options);
      };

      const errorCallBack = (err) => {
        console.log(err);
      };

      const searchParams = {
        query: JSON.stringify({ filter: { room_external_id: id } }),
        customer: searchValue || "",
      };

      REQUESTS.BOOKING.BOOK.GET(searchParams, successCalBack, errorCallBack);
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      email: values.email,
      phone: values.phone,
      room_id: room.id,
      name: values.full_name,
      language_id: values.language_id,
      country_id: values.country_id,
      application_form: form.getFieldValue("form_application"),

      guest_group_id: values.guest_group_id,
      arival_date: parseYearMonthDayHoursMinutes(values.arival_date._d),
      planned_departure_date: parseYearMonthDayHoursMinutes(
        values.planned_departure_date._d
      ),
      reservation_number: values.reservation_number,
      comment: values.comment,
    };

    function callback() {
      setLoading(false);
      onClose();
      getRoomsList();
    }

    function errorCallback(err) {
      setLoading(false);

      setErrorText(err);
    }

    REQUESTS.GUEST.ADD(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const getGuestGroupsOption = () => {
    REQUESTS.HOST_GROUPS.GET((data) => {
      const groups = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setGuestGroupsOption(groups);
    });
  };

  const handleSearch = (value) => {
    console.log(value, "value");

    const data = roomData?.rows?.find((item) => item.id === value);
    form.setFieldsValue({
      full_name: data?.customer_persona_datum?.full_name,
      email: data?.customer_persona_datum?.email,
      phone: data?.customer_persona_datum?.phone,
      language_id: data?.customer_persona_datum?.language_id,
      country_id: data?.customer_persona_datum?.country_id,
      guest_group_id: data?.guest_group_id,
      comment: data?.comment,
    });
  };

  return (
    <Drawer
      title={`${translate["New guest in room"] || EN["New guest in room"]} ${room?.id}`}
      placement="right"
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        name="guest-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h1>{translate["Guest Information"] || EN["Guest Information"]}</h1>
        <Form.Item name="form_application">
          <Radio.Group>
            {formApplication?.map((item) => {
              return (
                <Radio value={item.value} key={item.id}>
                  {item.value}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={translate["Full name"] || EN["Full name"]}
          tooltip={{
            title: "Host full name",
            icon: ICONS.INFO,
          }}
          name="full_name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Full name"] || EN["Full name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate["Email"] || EN["Email"]}
          tooltip={{
            title: "Email Address",
            icon: ICONS.INFO,
          }}
          name="email"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Email"] || EN["Email"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate["Mobile Phone Number"] || EN["Mobile Phone Number"]}
          tooltip={{
            title: "Mobile Phone Number",
            icon: ICONS.INFO,
          }}
          name="phone"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Phone number"] || EN["Phone number"]
              }`,
            },
          ]}
        >
          <InputNumber controls={false} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label={translate["Reservation Number"] || EN["Reservation Number"]}
          tooltip={{
            title: "Reservation Number",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="reservation_number" noStyle>
            <Select
              showSearch
              placeholder="Search reservation number"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
                handleSearch(value);
              }}
              onSearch={(value) => {
                setSearchValue(value);

                const newOption = {
                  label: value,
                  value: value,
                };

                setOptionReservation((prevOptions) => {
                  if (!prevOptions.some((option) => option.value === value)) {
                    return [...prevOptions, newOption];
                  }
                  return prevOptions;
                });
              }}
              filterOption={false}
              options={optionReservation}
              notFoundContent={null}
            />
          </Form.Item>
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label={translate["Arrival Date"] || EN["Arrival Date"]}
            tooltip={{
              title: "Choose a arival date",
              icon: ICONS.INFO,
            }}
            name="arival_date"
            rules={[
              {
                required: true,
                message: `${translate["Please select"] || EN["Please select"]} ${
                  translate["Arrival Date"] || EN["Arrival Date"]
                }`,
              },
            ]}
          >
            <DatePicker style={{ width: 250 }} />
          </Form.Item>

          <Form.Item
            label={translate["Planned Departure Date"] || EN["Planned Departure Date"]}
            tooltip={{
              title: "Choose a planned departure date",
              icon: ICONS.INFO,
            }}
            name="planned_departure_date"
            rules={[
              {
                required: true,
                message: `${translate["Please select"] || EN["Please select"]} ${
                  translate["Planned Departure Date"] || EN["Planned Departure Date"]
                }`,
              },
            ]}
          >
            <DatePicker style={{ width: 250 }} />
          </Form.Item>
        </div>
        <Form.Item
          label={translate[["Language"]] || EN[["Language"]]}
          name="language_id"
          tooltip={{
            title: "Choose a language",
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Language"] || EN["Language"]
              }`,
            },
          ]}
        >
          <Select
            showSearch
            options={languagesOption}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={translate["Country"] || EN["Country"]}
          name="country_id"
          tooltip={{
            title: "Choose a country",
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Country"] || EN["Country"]
              }`,
            },
          ]}
        >
          <Select
            showSearch
            options={countriesOptions}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={translate["Guest groups"] || EN["Guest groups"]}
          tooltip={{
            title: "Choose a host group",
            icon: ICONS.INFO,
          }}
          name="guest_group_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Guest groups"] || EN["Guest groups"]
              }`,
            },
          ]}
        >
          <Select showArrow allowClear={true} options={guestGropsOption} />
        </Form.Item>
        <Form.Item
          label={translate["Comment"] || EN["Comment"]}
          tooltip={{
            title: "Comment",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="comment" noStyle>
            <Input.TextArea rows={4} onResize={false} />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
